
export const AlbumItem = ({ album, onClick }) => {
    return (
        <li key={album.id} className="-mx-2" onClick={() => onClick(album.id)}>
            <button className="flex items-center justify-between w-full p-2">
                <div className="flex items-center">
                    <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                        <img
                            src={album.cover}
                            width="40" height="40" 
                            alt="Patricia Semklo" />

                    </div>
                    <div className="truncate md:w-48 text-left">
                        <span className="text-sm font-medium text-gray-100">{album.name}</span>
                    </div>
                </div>
            </button>
        </li>
    )
}