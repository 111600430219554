import React, { useState, useRef, useEffect } from 'react';
import {
  useParams
} from "react-router-dom";

import PlayerSidebar from '../partials/player/PlayerSidebar';
import PlayerHeader from '../partials/player/PlayerHeader';
import PlayerBody from '../partials/player/PlayerBody';
import PlayerFooter from '../partials/player/PlayerFooter';
import useAudio from '../hooks/useAudio';
import useFirestore from '../hooks/useFirestore';

function Player() {

  const contentArea = useRef(null)

  const { id } = useParams()
  const { albums, changeAlbumId, tracks, signInGoogle, user, signOut } = useFirestore()

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    if (id) {
      changeAlbumId(id)
      setSidebarOpen(false)
    } else {
      setSidebarOpen(true)
    }
  }, [id])

  const album = albums?.find(album => album.id === id)

  const {
    isPlaying,
    onPlayPauseClick,
    currentPercentage,
    trackProgress,
    duration,
    onScrub,
    onScrubEnd,
    onVolumeChange,
    volume,
    changeTrack,
    nextTrack,
    previousTrack
  } = useAudio({ src: "", album, tracks });

  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
`;

  return (
    <main className="flex h-screen overflow-hidden">
      {
        user ? (<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" ref={contentArea}>
          <div style={{ height: 'calc(100vh - 64px)' }} className="bg-gray-800">
            <div className="relative flex">
              <PlayerSidebar changeAlbumId={changeAlbumId} isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} albums={albums} />
              <div className={`flex-grow flex bg-gray-800 flex-col mb-12 md:translate-x-0 transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-1/3' : 'translate-x-0'}`}>
                <PlayerHeader isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} album={album} signOut={signOut} user={user} />
                <PlayerBody changeTrack={changeTrack} tracks={tracks} />
              </div>
            </div>
          </div>
          <PlayerFooter
            trackProgress={trackProgress}
            isPlaying={isPlaying}
            duration={duration}
            onScrub={onScrub}
            volume={volume}
            onScrubEnd={onScrubEnd}
            trackStyling={trackStyling}
            onVolumeChange={onVolumeChange}
            nextTrack={nextTrack}
            previousTrack={previousTrack}
            onPlayPauseClick={onPlayPauseClick} />

        </div>
        ) : (
          <div>
            <button onClick={signInGoogle}>
              login
            </button>
          </div>
        )
      }
      {/* Content area */}
    </main>
  );
}

export default Player;