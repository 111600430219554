import React from 'react';
import back from './../../images/back.svg'
import pause from './../../images/pause.svg'
import next from './../../images/next.svg'
import playButton from './../../images/play-button.svg'

function PlayerFooter({
  onPlayPauseClick,
  isPlaying,
  trackProgress,
  duration,
  onScrub,
  onScrubEnd,
  trackStyling,
  onVolumeChange,
  nextTrack,
  previousTrack,
  volume
}) {
  return (
    <div className="fixed w-full bottom-0 z-50">
      <div className="flex items-center justify-between bg-white border-t border-gray-900 bg-gray-900 px-4 sm:px-6 md:px-5 h-16">
        <div className="grid-cols-3 mx-5" style={{ minWidth: '90px' }}>

          <button className="text-2xl	" onClick={previousTrack}>
            <img src={back} width="20px" height="20px" />
          </button>
          {
            isPlaying ? <button className="mx-2 text-2xl" onClick={() => onPlayPauseClick(false)}>
              <img src={pause} width="20px" height="20px" />
            </button> : <button className="mx-2 text-2xl" onClick={() => onPlayPauseClick(true)}>
            <img src={playButton} width="20px" height="20px" />

            </button>
          }

          <button className="text-2xl	" onClick={nextTrack}>
          <img src={next} width="20px" height="20px" />

          </button>
        </div>

        <div className="flex-grow mr-3">
          <input
            value={trackProgress}
            max={Number.isNaN(duration) ? 0 : duration ?? 0}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            onChange={(e) => onScrub(e.target.value)}
            className="range-input w-full bg-gray-100 border-transparent focus:bg-white focus:border-gray-300"
            type="range"
            style={{ background: trackStyling }}
          />
        </div>
        <div className="mr-3 md:flex hidden">
          <div className="leading-8">
            🔈
          </div>

          <input
            className="range-input w-full bg-gray-100 border-transparent focus:bg-white focus:border-gray-300"
            type="range"
            value={volume * 100}
            onChange={e => onVolumeChange(e.target.value)}
            placeholder="Aa" />
        </div>
      </div>
    </div>
  );
}

export default PlayerFooter;
