import React from 'react';

function MessagesHeader({
  isSidebarOpen,
  setSidebarOpen,
  album,
  signOut,
  user
}) {
  return (
    <div className="sticky top-0 ">
      <div className="flex bg-gray-900 items-center justify-between px-4 sm:px-6 md:px-5 h-16">

        <div className="flex items-center">
          {/* Close button */}
          <button
            className="md:hidden text-gray-400 hover:text-gray-500 mr-4"
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            aria-controls="messages-sidebar"
            aria-expanded={isSidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {album ?
          <>
            <img className="w-10 mr-3" alt="Album cover" src={album?.cover} />

            <h2 className="flex-grow font-semibold text-gray-100 truncate ">{album?.name}</h2>

          </>

          : null}
        {/* People */}

        <div class="flex items-center">
          <button class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3" onClick={signOut}>
            <img class="rounded-full"
              src={user.photoURL}
              width="40"
              height="40"
              alt={user.displayName} />

          </button>
        </div>
      </div>
    </div>
  );
}

export default MessagesHeader;
