import { useRef, useState, useEffect, useCallback } from 'react'

const useAudio = ({ src, album, tracks }) => {

    const [isPlaying, setPlaying] = useState(false)
    const audioRef = useRef(new Audio(src));
    const intervalRef = useRef();
    const [trackProgress, setTrackProgress] = useState(0);
    const [volume, setVolume] = useState(1)
    const [trackIndex, setTrackIndex] = useState(0)

    const { duration } = audioRef.current;
    const currentPercentage = duration
        ? `${(trackProgress / duration) * 100}%`
        : "0%";

    const onPlayPauseClick = useCallback(val => {
        setPlaying(val)
    }, [])


    const changeTrack = useCallback((index) => {
        const track = tracks?.[index]

        if (track && index >= 0) {
            audioRef.current.pause();
            audioRef.current = new Audio(track.file);
            audioRef.current.volume = volume
            setPlaying(true)
            audioRef.current.play();
            startTimer();
            setTrackIndex(index)
            changeSession(track)
        } else {
            audioRef.current.pause();
            setPlaying(false)
            clearInterval(intervalRef.current);
        }
    }, [tracks, volume])

    useEffect(() => {
        navigator.mediaSession.setActionHandler('nexttrack', () => {
            const newIndex = trackIndex + 1
            setTrackIndex(newIndex)
            changeTrack(newIndex)
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
            const newIndex = trackIndex - 1
            setTrackIndex(newIndex)
            changeTrack(newIndex)
        });


    }, [trackIndex])

    const nextTrack = useCallback(() => {
        const newIndex = trackIndex + 1
        setTrackIndex(newIndex)
        changeTrack(newIndex)
    }, [trackIndex])

    const previousTrack = useCallback(() => {
        const newIndex = trackIndex - 1
        setTrackIndex(newIndex)
        changeTrack(newIndex)
    }, [trackIndex])


    const changeSession = useCallback(track => {

        if (navigator.mediaSession && album?.cover && album?.name ) {
            console.log('UPP')
            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: track.title,
                artist: track.artist,
                album: album.name,
                artwork: [
                    { src: album.cover, sizes: '96x96', type: 'image/png' },
                    { src: album.cover, sizes: '128x128', type: 'image/png' },
                    { src: album.cover, sizes: '192x192', type: 'image/png' },
                    { src: album.cover, sizes: '256x256', type: 'image/png' },
                    { src: album.cover, sizes: '384x384', type: 'image/png' },
                    { src: album.cover, sizes: '512x512', type: 'image/png' },
                ]
            });

            navigator.mediaSession.setActionHandler("play", () => {
                startTimer();
                setPlaying(true);
                audioRef.current.play();
            });

            navigator.mediaSession.setActionHandler("pause", () => {
                startTimer();
                setPlaying(false);
                audioRef.current.pause();
            });
        }
    }, [album])



    const startTimer = useCallback(() => {
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setPlaying(false);
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, [500]);
    }, [])

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    const onScrub = useCallback(value => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    }, [])

    const onVolumeChange = useCallback(value => {
        const volume = value / 100
        setVolume(volume)
        audioRef.current.volume = volume
    }, [])

    const onScrubEnd = useCallback(() => {
        startTimer();
    }, [])

    return {
        isPlaying,
        onPlayPauseClick,
        audioRef,
        changeTrack,
        currentPercentage,
        trackProgress,
        duration,
        onScrub,
        onScrubEnd,
        onVolumeChange,
        volume,
        nextTrack,
        previousTrack
    }
}

export default useAudio