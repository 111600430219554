import AWS from 'aws-sdk'
import { useState } from 'react'
const S3_BUCKET = 'music-lib-zef';
const AWS_REGION = 'eu-central-1';
const ACCESS_KEY = 'AKIAVKDA3SD6NG5YIWZ6';
const SECRET_ACCESS_KEY = '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';

AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: AWS_REGION,
})


export const useAWS = () => {
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const uploadFile = (file, name = file.name) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: name
        };

        const url = `https://${S3_BUCKET}.s3.${AWS_REGION}.amazonaws.com/${name}`
        return new Promise((resolve) => {
            myBucket.putObject(params)
                .on('httpUploadProgress', (evt) => {
                    setProgress(Math.round((evt.loaded / evt.total) * 100))
                })
                .send((err, a, b, c) => {

                    resolve(url)
                    if (err) console.log(err)
                })
        })

    }

    return {
        uploadFile
    }

}
