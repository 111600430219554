import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth'
import { useAWS } from './useAws';


const config = {
    apiKey: "AIzaSyBtzmCF5irUxEnP-71nCumyKGl9bJjX5kY",
    authDomain: "music-lib-784d1.firebaseapp.com",
    projectId: "music-lib-784d1",
    storageBucket: "music-lib-784d1.appspot.com",
    messagingSenderId: "195721886642",
    appId: "1:195721886642:web:5c147b715b7c30ab8102ab"
};



firebase.initializeApp({
    ...config
})

const firestore = firebase.firestore()
const auth = firebase.auth();

const useFirestore = () => {
    const { uploadFile } = useAWS()
    const albumsRef = firestore.collection('albums');
    const tracksRef = firestore.collection('tracks');
    const [user] = useAuthState(auth);

    const [albumId, setAlbumId] = useState(null);
    const [albums] = useCollectionData(albumsRef, { idField: 'id' });

    const signInGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        await auth.signInWithPopup(provider);
    }

    const signOut = async () => {
        await auth.signOut();
    }


    const query = useMemo(() => {
        return tracksRef.where('albumId', '==', albumId)
    }, [albumId])

    const [tracks] = useCollectionDataOnce(query, { idField: 'id' });


    const changeAlbumId = useCallback(id => {
        setAlbumId(id)
    }, [])

    const addTrack = useCallback(async (track) => {
        const snaps = await albumsRef.where('name', '==', track.album).get();

        let albumId

        snaps.forEach((doc) => {
            albumId = doc.id
        });
        if (!albumId) {


            const { picture, ...valid } = track

            const imgId = nanoid();
            const cover = picture?.[0];

            const format = cover?.format?.split('/')[1];

            let url;
            if (cover) {
                url = await uploadFile(cover.data, `${imgId}.${format}`);
            }

            const album = {
                name: track.album,
                artist: track.albumartist,
                cover: url,
            }

            const res = await albumsRef.add(album)
            albumId = res.id
        }
        const { picture, ...valid } = track

        await tracksRef.add({
            ...valid,
            albumId
        })
    }, [albums])


    return {
        addTrack,
        albums,
        changeAlbumId,
        tracks,
        signInGoogle,
        signOut,
        user
    }
}

export default useFirestore