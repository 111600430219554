import React from 'react';
import { AlbumItem } from './AlbumItem';
import { useHistory } from "react-router-dom";

function PlayerSidebar({
  isSidebarOpen,
  setSidebarOpen,
  albums
}) {

  const history = useHistory();

  const onClick = (id) => {
    history.push(`/${id}`);
    setSidebarOpen(!isSidebarOpen)
  }
  return (
    <div
      id="messages-sidebar"
      className={`absolute z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transform transition-transform duration-200 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
    >
      <div className="sticky top-0 bg-gray-900 overflow-x-hidden overflow-y-auto no-scrollbar flex-shrink-0 md:w-72" style={{ height: 'calc(100vh - 64px)' }}>

        {/* #Marketing group */}
        <div>
          {/* Group header */}
          <div className="sticky top-0 z-10">
            <div className="flex items-center bg-gray-900 px-5 h-16">
              <div className="w-full flex items-center justify-between">
                <span className="font-semibold text-gray-100">Albums</span>
              </div>
            </div>
          </div>
          {/* Group body */}
          <div className="px-5 py-4">
            <ul>
              {
                albums?.map((album) => {
                  return (
                    <AlbumItem key={album.id} onClick={onClick} album={album} />
                  )
                })
              }

            </ul>

          </div>
        </div>

      </div>
    </div>
  );
}

export default PlayerSidebar;
