import React from 'react';
import format from 'format-duration';
import audioFile from './../../images/audio-file.png'

function PlayerBody({ tracks, changeTrack }) {
  return (
    <div className="flex-grow px-4 sm:px-6 md:px-5 py-6">
      <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm bg-gray-900 border border-gray-700">
        <div className="p-3">

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-200 bg-gray-900 rounded-sm">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Title</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Length</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm font-medium divide-y divide-gray-500">
                {/* Row */}
                {
                  tracks?.sort((el1, el2


                  ) => el1?.track?.no - el2?.track?.no)?.map((track, i) => {
                    return (
                      <tr key={track.id} className="cursor-pointer border-none" onClick={() => changeTrack(i)}>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center mr-2 sm:mr-3">
                              <img alt="Album cover" className="ml-1" src={audioFile} width="20" height="20" />
                            </div>
                            <div className="text-gray-200">{track.title}</div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-center text- green-500">{format(track.duration * 1000)}</div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>

          </div>
        </div>
      </div>


    </div>
  );
}

export default PlayerBody;
